import React,{useEffect} from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { connect } from "react-redux"
import {getPropertyAction} from '../../../redux/propertiesDucks'
import { getType, getOperations, getFakeAddres, getLocation } from "../../../helpers/helper.properties"
import { getCover } from "../../../helpers/helper.rendering"

const ClientSideOnlyLazy = React.lazy(() =>
  import("../../../components/Propiedad/main")
)
const ClientSideOnlyLazyAbout = React.lazy(() =>
  import('../../../components/Propiedad/about')
)

//Components
import Loading from "../../../components/Loading"

const IndexPage = (props) => {

  const isSSR = typeof window === "undefined"

  const {id, dispatch, api_key, property, serverData, loading_property} = props

  useEffect(() => {
    if(api_key && id){
      dispatch(getPropertyAction(id))
    }
  },[api_key,id])

  return false ? <Loading absolute={true} /> : (
      <Layout property={property}>
            {/* <Seo 
                title={getType(serverData) + " en "  + getOperations(serverData)[0] + " - " + getFakeAddres(serverData)}
                meta={"Encontrá la propiedad que estabas buscando. " + getFakeAddres(serverData) + " en " + getOperations(serverData)[0] +  " en " +  getLocation(serverData)} 
                description={"Encontrá la propiedad que estabas buscando. " + getFakeAddres(serverData) + " en " + getOperations(serverData)[0] +  " en " +  getLocation(serverData)} 
                image={getCover(serverData.photos)?.original}
            /> */}
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <ClientSideOnlyLazy id={id} />
                <ClientSideOnlyLazyAbout />
              </React.Suspense>
            )}
            {/* <Expand /> */}
      </Layout>
  )
}

export default connect(state => ({
    api_key: state.settings.keys.tokko,
    loading_property:state.properties.loading_property,
    property:state.properties.property
}),null)(IndexPage);